import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form';
import { components, DataFlattener, constants, useTranslation, useServices } from 'cng-web-lib'
import { Card, CardContent, Grid, Chip, Box } from '@material-ui/core'
import Namespace from 'src/constants/locale/Namespace'
import DbAdminStatusConfigKeys from 'src/constants/locale/key/DbAdminStatusConfig'
import FileForUserUrls from 'src/apiUrls/FileUserApiUrls.js'
import DbAdminStatusConfigApiUrls from 'src/apiUrls/DbAdminStatusConfigApiUrls'
import makeValidationSchema from './MakeValidationSchema'
import CngBackdrop from 'src/components/cngcomponents/CngBackDrop';
import StatusConfigButtonComponent from './component/StatusConfigButtonComponent'
import { v4 as uuid } from 'uuid'

const {
  card: { CngSimpleCardHeader },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField
    }
  },
  table: {
    CngCrudTable,
    useFetchCodeMaintenanceLookup
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  module: '',
  type: '',
  searchCriteria1: '',
  searchCriteria2: '',
  currentStatus: '',
  newStatus: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const { setValue, getValues, reset, watch, trigger } = useFormContext();
  const { securedSendRequest } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.DB_ADMIN_STATUS_CONFIG)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [loadingState, setLoadingState] = useState(false);
  const [tableState, setTableState] = useState(uuid());

  const [tableDetailState, setTableDetailState] = useState(false);
  const [formDetailState, setFormDetailState] = useState(false);

  //dropdown state
  const [formState, setFormState] = useState(uuid());
  const [subModuleDetalsState, setSubModuleDetailState] = useState(false);
  const [subModuleState, setSubModuleState] = useState(uuid());
  const [updateStatusAPIState, setUpdateStatusAPIState] = useState('');

  const [configState, setConfigState] = useState(
    {
      labelModule: translatedTextsObject.module + ' *',
      labelType: translatedTextsObject.type + ' *',
      labelSearchCriteria1: translatedTextsObject.searchCriteria + '1 *',
      labelSearchCriteria2: translatedTextsObject.searchCriteria + '2 *',
      labelCurrentStatus: translatedTextsObject.currentStatus + ' *',
      labelNewStatus: translatedTextsObject.newStatus + ' *',
      labelUserId: translatedTextsObject.userId + ' *',
      dropDownStatusCodeType: '-',
      dropDownSubModuleType: '-',
      tableColumns: '',
      tableFetchFilters: '',
      tableFetchAPIUrl: '',
      formUpdateStatusAPIUrl: ''
    }
  )

  const SEARCH_ACTION = 'SEARCH';
  const UPDATE_ACTION = 'UPDATE';

  //Determine action code >> search/update
  watch('actionCode')

  useEffect(() => {
    let module = getValues('module');
    setValue('type', '')
    setFormDetailState(false)
    setTableDetailState(false)

    //Add on changes
    if (module == 'ACEHW') {
      configState.dropDownSubModuleType = 'DB_ADMIN_ACEHW_SUB_MODULE'
    }

    if (module == 'ACIHW') {
      configState.dropDownSubModuleType = 'DB_ADMIN_ACIHW_SUB_MODULE'
    }

    if (module == 'ACIOCN') {
      configState.dropDownSubModuleType = 'DB_ADMIN_ACIOCN_SUB_MODULE'
    }

    if (module == 'SHARED') {
      configState.dropDownSubModuleType = 'DB_ADMIN_SHARED_SUB_MODULE'
    }

    if (module == 'EHBL') {
      configState.dropDownSubModuleType = 'DB_ADMIN_EHBL_SUB_MODULE'
    }

    if (module == 'FDAPN') {
      configState.dropDownSubModuleType = 'DB_ADMIN_FDAPN_SUB_MODULE'
    }

    if (module == 'ACEISF') {
      configState.dropDownSubModuleType = 'DB_ADMIN_ACEISF_SUB_MODULE'
    }

    if (module == 'ACEOCN') {
      configState.dropDownSubModuleType = 'DB_ADMIN_ACEOCN_SUB_MODULE'
    }

    if (module == 'ACIAIR') {
      configState.dropDownSubModuleType = 'DB_ADMIN_ACIAIR_SUB_MODULE'
    }

    if (module == 'AES') {
      configState.dropDownSubModuleType = 'DB_ADMIN_AES_SUB_MODULE'
    }

    //refresh form details if module is not empty
    if (module != null && module != undefined && module != '') {
      setSubModuleState(uuid())
      setSubModuleDetailState(true);

      if(module=="AES"){
        setValue("type","AES")
      }
    }
  }, [getValues('module')]);

  useEffect(() => {
    let module = getValues('module');
    let type = getValues('type');
    if (type != '' && type != undefined && type != '') {

      //Add on changes
      if (module == 'ACEHW') {
        aceHighwayFormConfig(type)
      }

      if (module == 'ACIHW') {
        aciHighwayFormConfig(type)
      }

      if (module == 'ACIOCN') {
        aciOcnFormConfig(type);
      }

      if (module == 'SHARED') {
        sharedFormConfig(type)
      }

      if (module == 'EHBL') {
        ehblFormConfig(type)
      }

      if (module == 'FDAPN') {
        fdapnFormConfig();
      }

      if (module == 'ACEISF') {
        isfFormConfig()
      }

      if (module == 'ACEOCN') {
        aceOcnFormConfig(type)
      }

      if (module == 'ACIAIR') {
        aciAirFormConfig(type)
      }

      if (module == 'AES') {
        aesFormConfig(type)
      }

      setValue('searchCriteria1', '')
      setValue('searchCriteria2', '')
      setValue('currentStatus', '')
      setValue('newStatus', '')
      setValue('partyId', '')
      setFormDetailState(true)
      setFormState(uuid())
    }
  }, [getValues('type')]);

  const onSearch = () => {
    setValue('actionCode', 'search')

    let module = getValues('module');
    let type = getValues('type');

    //Add on changes
    if (module == 'ACEHW') {
      aceHighwayTableConfig(type);
    }

    if (module == 'ACIHW') {
      aciHighwayTableConfig(type);
    }

    if (module == 'ACIOCN') {
      aciOcnTableConfig(type);
    }

    if (module == 'SHARED') {
      sharedTableConfig(type);
    }

    if (module == 'EHBL') {
      ehblTableConfig(type)
    }

    if (module == 'FDAPN') {
      fdapnTableConfig();
    }

    if (module == 'ACEISF') {
      isfTableConfig(type)
    }

    if (module == 'ACEOCN') {
      aceOcnTableConfig(type)
    }

    if (module == 'ACIAIR') {
      aciAirTableConfig(type)
    }

    if (module == 'AES') {
      aesTableConfig();
    }

    //validate form
    const result = trigger()

    result.then(function (result) {
      if (result == true) {
        setTableDetailState(true);
        //refresh table 
        setTableState(uuid())
      }
    })
  }

  const sharedFormConfig = (type) => {
    //Hide the field based on type
    if (type == 'CREW_MASTER') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.crewId + ' *'
      shouldHideMap.searchCriteria2 = true;
    }

    if (type == 'EQUIPMENT_MASTER') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.equipmentId + ' *'
      shouldHideMap.searchCriteria2 = true;
    }

    if (type == 'CONVEYANCE_MASTER') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.conveyanceId + ' *'
      shouldHideMap.searchCriteria2 = true;
    }

    //set status code type
    configState.dropDownStatusCodeType = 'EQ_MASTER_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.SHARED_UPDATE_STATUS
  }

  const sharedTableConfig = (type) => {

    let searchCriteria1 = getValues('searchCriteria1');
    let searchCriteria2 = getValues('searchCriteria2');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    if (type == 'CREW_MASTER') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.crewId,
          field: 'crewId',
        },
        {
          title: translatedTextsObject.crewType,
          field: 'crewType',
        },
        {
          title: translatedTextsObject.firstName,
          field: 'firstName',
        }, {
          title: translatedTextsObject.lastName,
          field: 'lastName',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'EQ_MASTER_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'crewId', operator: 'like', value: searchCriteria1
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.SHARED_SEARCH_CREW_MASTER;
    }

    if (type == 'EQUIPMENT_MASTER') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.equipmentId,
          field: 'equipmentId',
        },
        {
          title: translatedTextsObject.carrierCode,
          field: 'carrierCode',
        },
        {
          title: translatedTextsObject.equipmentNumber,
          field: 'equipmentNo',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'EQ_MASTER_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'equipmentId', operator: 'like', value: searchCriteria1
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.SHARED_SEARCH_EQUIPMENT_MASTER;
    }

    if (type == 'CONVEYANCE_MASTER') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.conveyanceId,
          field: 'conveyanceID',
        },
        {
          title: translatedTextsObject.carrierCode,
          field: 'carrierCode',
        },
        {
          title: translatedTextsObject.conveyanceNumber,
          field: 'conveyanceNo',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'EQ_MASTER_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'conveyanceID', operator: 'like', value: searchCriteria1
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.SHARED_SEARCH_CONVEYANCE_MASTER;
    }

  }

  const aceHighwayFormConfig = (type) => {
    //Hide the field based on type
    if (type == 'MANIFEST') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.tripNumber + ' *'
      shouldHideMap.searchCriteria2 = true;
    } else if (type == 'SHIPMENT') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.tripNumber + ' **'
      configState.labelSearchCriteria2 = translatedTextsObject.shipmentNumber + ' **'
      shouldHideMap.searchCriteria2 = false;
    } else if (type == 'ACE_INBOND') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.inbond7512No + ' *'
      configState.labelSearchCriteria2 = translatedTextsObject.msgFunction + ' **'
      shouldHideMap.searchCriteria2 = true;
    } else if (type == 'INBOND_EVENT') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.inbond7512No + ' *'
      configState.labelSearchCriteria2 = translatedTextsObject.msgFunction + ' **'
      shouldHideMap.searchCriteria2 = true;
    } else if (type == 'INBOND_7512') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.inbond7512No + ' *'
      shouldHideMap.searchCriteria2 = true;
    }

    //set status code type
    configState.dropDownStatusCodeType = 'ACE_HW_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.ACE_HIGHWAY_UPDATE_STATUS
  }

  const aceHighwayTableConfig = (type) => {

    let tripNumber = getValues('searchCriteria1');
    let scn = getValues('searchCriteria2');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    if (type == 'MANIFEST') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.tripNumber,
          field: 'tripNo',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'tripNo', operator: 'like', value: tripNumber
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_HIGHWAY_SEARCH_MANIFEST;
    } else if (type == 'SHIPMENT') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.tripNumber,
          field: 'tripNo',
        },
        {
          title: translatedTextsObject.shipmentNumber,
          field: 'scn',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'tripNo', operator: 'like', value: tripNumber
        },
        {
          field: 'scn', operator: 'like', value: scn
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_HIGHWAY_SEARCH_SHIPMENT;
    } else if (type == 'ACE_INBOND') {
      let inbond7512No = getValues('searchCriteria1');
      let msgFunction = getValues('searchCriteria2');

      configState.tableColumns = [
        {
          title: translatedTextsObject.inbond7512No,
          field: 'inbond7512No',
        },
        {
          title: translatedTextsObject.msgFunction,
          field: 'msgFunction',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'MESSAGE_FUNCTION_INBOND' }],
              undefined,
              'code'
            )
          }
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'inbond7512No', operator: 'equal', value: inbond7512No
        },
        {
          field: 'msgFunction', operator: 'equal', value: msgFunction
        },
        {
          field: 'messageCode', operator: 'in', value: ['98', '833']
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_HIGHWAY_SEARCH_INBOND;
    } else if (type == 'INBOND_EVENT') {
      let inbond7512No = getValues('searchCriteria1');
      let msgFunction = getValues('searchCriteria2');

      configState.tableColumns = [
        {
          title: translatedTextsObject.inbond7512No,
          field: 'inbond7512No',
        },
        {
          title: translatedTextsObject.msgFunction,
          field: 'msgFunction',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_ACTION' }],
              undefined,
              'code'
            )
          }
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'inbond7512No', operator: 'equal', value: inbond7512No
        },
        {
          field: 'msgFunction', operator: 'equal', value: msgFunction
        },
        {
          field: 'messageCode', operator: 'equal', value: 'null'
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_HIGHWAY_SEARCH_INBOND;
    } else if (type == 'INBOND_7512') {
      let bondNumber = getValues('searchCriteria1');

      configState.tableColumns = [
        {
          title: translatedTextsObject.bondNumber,
          field: 'bondNumber',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER,
              undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
              undefined,
              'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        {
          field: 'bondNumber', operator: 'like', value: bondNumber
        },
        {
          field: 'status', operator: 'equal', value: status
        },
        {
          field: 'partyId', operator: 'equal', value: partyId
        }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_HIGHWAY_SEARCH_7512;
    }
  }

  const aciHighwayFormConfig = (type) => {
    //Hide the field based on type
    if (type == 'MANIFEST') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.tripNumber + ' *'
      shouldHideMap.searchCriteria2 = true;
    } else if (type == 'CARGO') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.tripNumber + ' **'
      configState.labelSearchCriteria2 = translatedTextsObject.ccn + ' **'
      shouldHideMap.searchCriteria2 = false;
    } else if (type == 'WACM') {
      //Override the label
      configState.labelSearchCriteria1 = 'Inbond Arrival ID *'
      shouldHideMap.searchCriteria2 = true;
    }

    //set status code type
    if (type == 'WACM') {
      configState.dropDownStatusCodeType = 'ACIHWY_RNS_STATUS'
    } else {
      configState.dropDownStatusCodeType = 'ACIHWY_STATUS'
    }

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.ACI_HIGHWAY_UPDATE_STATUS
  }

  const aciHighwayTableConfig = (type) => {
    let criteria1 = getValues('searchCriteria1');
    let criteria2 = getValues('searchCriteria2');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    if (type == 'MANIFEST') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.tripNumber,
          field: 'tripNum',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'tripNum', operator: 'like', value: criteria1 },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_HIGHWAY_SEARCH_MANIFEST;

    } else if (type == 'CARGO') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.tripNumber,
          field: 'tripNo',
        },
        {
          title: translatedTextsObject.ccn,
          field: 'ccn',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'tripNo', operator: 'like', value: criteria1 },
        { field: 'ccn', operator: 'like', value: criteria2 },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_HIGHWAY_SEARCH_CARGO;
    } else if (type == 'WACM') {
      configState.tableColumns = [
        { title: 'Inbond Arrival ID', field: 'id' },
        { title: translatedTextsObject.ccn, field: 'ccn' },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_RNS_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'id', operator: 'equal', value: criteria1 },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_HIGHWAY_SEARCH_ARRIVAL_CERT;
    }

  }

  const aciOcnFormConfig = (type) => {
    //Hide the field based on type
    if (type == 'A6' || type == 'AM') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.conveyanceReferenceNumber + ' *'
    } else {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.ccn + ' *'
    }
    shouldHideMap.searchCriteria2 = true;

    if(type == 'AM'){
       //set status code type
      configState.dropDownStatusCodeType = 'AM_STATUS'
    }else{
      //set status code type
      configState.dropDownStatusCodeType = 'ACIOCN_STATUS'
    }

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.ACI_OCN_UPDATE_STATUS
  }

  const aciOcnTableConfig = (type) => {
    let criteria = getValues('searchCriteria1');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    if (type == 'A6') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.conveyanceReferenceNumber,
          field: 'convRefno',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'a6aStatus',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACIOCN_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'convRefno', operator: 'like', value: criteria },
        { field: 'a6aStatus', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_OCN_SEARCH_A6;

    } else if (type == 'A6A') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.ccn,
          field: 'ccn',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'a6aStatus',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACIOCN_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'ccn', operator: 'like', value: criteria },
        { field: 'a6aStatus', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_OCN_SEARCH_A6A;
    } else if (type == 'AM') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.conveyanceReferenceNumber,
          field: 'conveyancereferencenumber',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'AM_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'conveyancereferencenumber', operator: 'like', value: criteria },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_OCN_SEARCH_AM;

    }
  }

  const ehblFormConfig = (type) => {
    //Hide the field based on type
    if (type == 'HOUSE_BILL') {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.primaryCcn + ' **'
      configState.labelSearchCriteria2 = translatedTextsObject.ccn + ' **'
      shouldHideMap.searchCriteria2 = false;
    } else {
      //Override the label
      configState.labelSearchCriteria1 = translatedTextsObject.prevCcn + ' *'
      shouldHideMap.searchCriteria2 = true;
    }

    //set status code type
    configState.dropDownStatusCodeType = 'EHBL_HB_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.EHBL_UPDATE_STATUS
  }

  const ehblTableConfig = (type) => {
    let criteria1 = getValues('searchCriteria1');
    let criteria2 = getValues('searchCriteria2');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    if (type == 'HOUSE_BILL') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.primaryCcn,
          field: 'primaryCcn',
        },
        {
          title: translatedTextsObject.ccn,
          field: 'ccn',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'primaryCcn', operator: 'like', value: criteria1 },
        { field: 'ccn', operator: 'like', value: criteria2 },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.EHBL_HOUSE_BILL_SEARCH;

    } else if (type == 'CLOSE_MSG') {
      configState.tableColumns = [
        {
          title: translatedTextsObject.prevCcn,
          field: 'prevCcn',
        },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'EHBL_HB_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'prevCcn', operator: 'like', value: criteria1 },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.EHBL_CLOSE_MSG_SEARCH;
    }
  }

  const fdapnFormConfig = () => {
    //Override the label
    configState.labelSearchCriteria1 = translatedTextsObject.referenceNumber + ' *'
    shouldHideMap.searchCriteria2 = true;

    //set status code type
    configState.dropDownStatusCodeType = 'FDA_PN_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.FDA_PN_UPDATE_STATUS
  }

  const fdapnTableConfig = () => {
    let referenceIdNum = getValues('searchCriteria1');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    configState.tableColumns = [
      {
        title: translatedTextsObject.referenceNumber,
        field: 'referenceIdNum',
      },
      {
        title: translatedTextsObject.currentStatus,
        field: 'status',
        filterPlaceholder: ' ',
        customLookup: () => {
          return fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER, undefined,
            [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_STATUS' }],
            undefined, 'code'
          )
        }
      }
    ]

    configState.tableFetchFilters = [
      { field: 'referenceIdNum', operator: 'like', value: referenceIdNum },
      { field: 'status', operator: 'equal', value: status },
      { field: 'partyId', operator: 'equal', value: partyId }
    ]

    configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.FDA_PN_SEARCH;
  }
  

  const isfFormConfig = () => {
    configState.labelSearchCriteria1 = 'Importer Document Number **'
    configState.labelSearchCriteria2 = 'BL Number **'
    shouldHideMap.searchCriteria2 = false

    //set status code type
    configState.dropDownStatusCodeType = 'ACE_ISF_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.ACE_ISF_UPDATE_STATUS
  }

  const isfTableConfig = (type) => {
    let criteria1 = getValues('searchCriteria1');
    let criteria2 = getValues('searchCriteria2');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    configState.tableColumns = [
      { title: 'Importer Document Number', field: 'importerRefNo' },
      {
        title: 'BL Number',
        render: (data) => {
          let content = []

          data.shipments.map(i => {
            content.push(<Chip key='first' label={i.blType + ' | ' + i.blNo + (i.blMatched ? ' | ' + i.blMatched : '')} />)
          })

          return <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>{content}</Box>
        }
      },
      {
        title: translatedTextsObject.currentStatus,
        field: 'status',
        filterPlaceholder: '',
        customLookup: () => {
          return fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER, undefined,
            [{ field: 'codeType', operator: EQUAL, value: 'ACE_ISF_STATUS' }],
            undefined, 'code'
          )
        }
      }
    ]

    configState.tableFetchFilters = [
      { field: 'importerRefNo', operator: 'like', value: criteria1 },
      { field: 'blNo', operator: 'like', value: criteria2 },
      { field: 'status', operator: 'equal', value: status },
      { field: 'partyId', operator: 'equal', value: partyId }
    ]

    if (type == 'ISF5') {
      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_ISF5_SEARCH;
    } else {
      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_ISF10_SEARCH;
    }
  }

  const aceOcnFormConfig = (type) => {
    //Hide the field based on type
    if (type == 'MANIFEST') {
      configState.labelSearchCriteria1 = 'Manifest ID *'
      shouldHideMap.searchCriteria2 = true;
    } else {
      configState.labelSearchCriteria1 = 'Manifest ID **'
      configState.labelSearchCriteria2 = 'Bill Number **'
      shouldHideMap.searchCriteria2 = false;
    }

    //set status code type
    configState.dropDownStatusCodeType = 'ACE_OCN_DBADMIN_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.ACE_OCN_UPDATE_STATUS
  }

  const aceOcnTableConfig = (type) => {
    let criteria1 = getValues('searchCriteria1');
    let criteria2 = getValues('searchCriteria2');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');


    if (type == 'MANIFEST') {
      configState.tableColumns = [
        { title: 'Manifest ID', field: 'id' },
        { title: 'SCAC', field: 'scac' },
        { title: 'Vessel Name', field: 'vesselName' },
        { title: 'Voyage Number', field: 'voyageNumber' },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_OCN_DBADMIN_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'id', operator: 'equal', value: criteria1 },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_OCN_MANIFEST_SEARCH;

    } else if (type == 'BILLOFLADING') {
      configState.tableColumns = [
        { title: 'Manifest ID', field: 'manifestId' },
        { title: 'Bill Number', field: 'billNumber' },
        {
          title: translatedTextsObject.currentStatus,
          field: 'status',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'ACE_OCN_DBADMIN_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'manifestId', operator: 'equal', value: criteria1 },
        { field: 'billNumber', operator: 'like', value: criteria2 },
        { field: 'status', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACE_OCN_BOL_SEARCH;
    }
  }

  const aciAirFormConfig = (type) => {
    //Hide the field based on type
    shouldHideMap.searchCriteria2 = true;

    if (type == 'CONVEYANCE') {
      configState.labelSearchCriteria1 = 'Conveyance Reference Number *'
    } else if (type == 'ACR') {
      configState.labelSearchCriteria1 = 'CCN *'
    } else if (type == 'SSR') {
      configState.labelSearchCriteria1 = 'CCN *'
      configState.labelSearchCriteria2 = 'Part Arrival Reference Number *'
      shouldHideMap.searchCriteria2 = false;
    } else if (type == 'S10') {
      configState.labelSearchCriteria1 = 'SRN *'
    }

    //set status code type
    configState.dropDownStatusCodeType = 'DBADMIN_ACIAIR_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.ACI_AIR_UPDATE_STATUS
  }

  const aciAirTableConfig = (type) => {
    let criteria1 = getValues('searchCriteria1');
    let criteria2 = getValues('searchCriteria2');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');


    if (type == 'CONVEYANCE') {
      configState.tableColumns = [
        { title: 'Conveyance Reference Number', field: 'conveyanceRefNo' },
        {
          title: translatedTextsObject.currentStatus,
          field: 'hdrStatus',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'DBADMIN_ACIAIR_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'conveyanceRefNo', operator: 'like', value: criteria1 },
        { field: 'formType', operator: EQUAL, value: 187 },
        { field: 'hdrStatus', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_AIR_SEARCH_CONVEYANCE;

    } else if (type == 'ACR') {
      configState.tableColumns = [
        { title: 'CCN', field: 'ccn' },
        {
          title: translatedTextsObject.currentStatus,
          field: 'hdrStatus',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'DBADMIN_ACIAIR_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'ccn', operator: 'like', value: criteria1 },
        { field: 'formType', operator: EQUAL, value: 802 },
        { field: 'hdrStatus', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_AIR_SEARCH_HEADER;

    } else if (type == 'SSR') {
      configState.tableColumns = [
        { title: 'CCN', field: 'ccn' },
        { title: 'Party Arrival Reference Number', field: 'partArrivalRefNo' },
        {
          title: translatedTextsObject.currentStatus,
          field: 'hdrStatus',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'DBADMIN_ACIAIR_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'ccn', operator: 'like', value: criteria1 },
        { field: 'partArrivalRefNo', operator: 'like', value: criteria2 },
        { field: 'formType', operator: EQUAL, value: 786 },
        { field: 'hdrStatus', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_AIR_SEARCH_HEADER;

    } else if (type == 'S10') {
      configState.tableColumns = [
        { title: 'Supplementary Reference Number', field: 'ccn' },
        {
          title: translatedTextsObject.currentStatus,
          field: 'hdrStatus',
          filterPlaceholder: ' ',
          customLookup: () => {
            return fetchCodeMaintenanceLookup(
              constants.CodeMaintenanceType.CODE_MASTER, undefined,
              [{ field: 'codeType', operator: EQUAL, value: 'DBADMIN_ACIAIR_STATUS' }],
              undefined, 'code'
            )
          }
        }
      ]

      configState.tableFetchFilters = [
        { field: 'ccn', operator: 'like', value: criteria1 },
        { field: 'formType', operator: 'equal', value: 687 },
        { field: 'hdrStatus', operator: 'equal', value: status },
        { field: 'partyId', operator: 'equal', value: partyId }
      ]

      configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.ACI_AIR_SEARCH_HEADER;
    }
  }

  const aesFormConfig = () => {
    //Override the label
    configState.labelSearchCriteria1 = translatedTextsObject.shipmentReferenceNumber + ' *'
    shouldHideMap.searchCriteria2 = true;

    //set status code type
    configState.dropDownStatusCodeType = 'AES_STATUS'

    //set update status API
    configState.formUpdateStatusAPIUrl = DbAdminStatusConfigApiUrls.AES_UPDATE_STATUS
  }

  const aesTableConfig = () => {
    let referenceIdNum = getValues('searchCriteria1');
    let status = getValues('currentStatus');
    let partyId = getValues('partyId');

    configState.tableColumns = [
      {
        title: translatedTextsObject.shipmentReferenceNumber,
        field: 'shipmentReferenceNumber',
      },
      {
        title: translatedTextsObject.currentStatus,
        field: 'status',
        filterPlaceholder: ' ',
        customLookup: () => {
          return fetchCodeMaintenanceLookup(
            constants.CodeMaintenanceType.CODE_MASTER, undefined,
            [{ field: 'codeType', operator: EQUAL, value: 'FDA_PN_STATUS' }],
            undefined, 'code'
          )
        }
      }
    ]

    configState.tableFetchFilters = [
      { field: 'shipmentReferenceNumber', operator: 'like', value: referenceIdNum },
      { field: 'status', operator: 'equal', value: status },
      { field: 'partyId', operator: 'equal', value: partyId }
    ]

    configState.tableFetchAPIUrl = DbAdminStatusConfigApiUrls.AES_SEARCH;
  }

  function makeTranslatedTextsObject() {
    let dbAdminStatusConfig = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.TITLE
    )
    let module = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.MODULE
    )
    let type = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.TYPE
    )

    let searchCriteria = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.SEARCH_CRITERIA
    )

    let tripNumber = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.TRIP_NUMBER
    )
    let shipmentNumber = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.SHIPMENT_NUMBER
    )
    let ccn = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CCN
    )

    let inbond7512No = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.INBOND_7512_NO
    )
    let msgFunction = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.MSG_FUNCTION
    )

    let currentStatus = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CURRENT_STATUS
    )
    let newStatus = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.NEW_STATUS
    )

    let result = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.RESULT
    )

    let userId = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.USER_ID
    )

    let successMsg = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.SUCCESS_MSG
    )

    let errorMsg = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.ERROR_MSG
    )

    let crewId = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CREW_ID
    )

    let equipmentId = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.EQUIPMENT_ID
    )

    let conveyanceId = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CONVEYANCE_ID
    )

    let carrierCode = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CARRIER_CODE
    )

    let conveyanceNumber = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CONVEYANCE_NUMBER
    )

    let equipmentNumber = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.EQUIPMENT_NUMBER
    )

    let crewType = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CREW_TYPE
    )

    let firstName = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.FIRST_NAME
    )

    let lastName = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.LAST_NAME
    )

    let referenceNumber = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.REFERENCE_NUMBER
    )

    let primaryCcn = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.PRIMARY_CCN
    )

    let prevCcn = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.PREV_CCN
    )

    let conveyanceReferenceNumber = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CONVEYANCE_REFERENCE_NUMBER
    )

    let shipmentReferenceNumber = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.SHIPMENT_REFERENCE_NUMBER
    )


    return {
      dbAdminStatusConfig,
      module,
      type,
      searchCriteria,
      tripNumber,
      shipmentNumber,
      ccn,
      inbond7512No,
      msgFunction,
      currentStatus,
      newStatus,
      result,
      userId,
      successMsg,
      errorMsg,
      crewId,
      equipmentId,
      conveyanceId,
      carrierCode,
      conveyanceNumber,
      equipmentNumber,
      crewType,
      firstName,
      lastName,
      referenceNumber,
      primaryCcn,
      prevCcn,
      conveyanceReferenceNumber,
      shipmentReferenceNumber
    }
  }

  const onUpdate = () => {
    setValue('actionCode', 'update')

    let submitData = {}

    submitData.module = getValues('module')
    submitData.type = getValues('type')
    submitData.searchCriteria = [
      {
        key: 'searchCriteria1',
        value: getValues('searchCriteria1')
      },
      {
        key: 'searchCriteria2',
        value: getValues('searchCriteria2')
      },
      {
        key: 'currentStatus',
        value: getValues('currentStatus')
      }
    ]
    submitData.dataToUpdate = [
      {
        key: 'status',
        oldValue: getValues('currentStatus'),
        newValue: getValues('newStatus')
      }
    ]
    submitData.partyId = getValues('partyId')

    function onSuccess(response) {

      if (response.status == 200) {
        const data = response.data
        if (data.errorMessages != null && data.errorMessages != undefined && data.errorMessages.length > 0) {
          showNotification('error', data.errorMessages)
        } else {
          onSearch()
          showNotification('success', translatedTextsObject.successMsg)
        }
      } else {
        showNotification('error', translatedTextsObject.errorMsg)
      }
    }

    function onError(error) {
      console.error('Error', error)
      showNotification('error', translatedTextsObject.errorMsg)
    }

    function onComplete(response) {
      setLoadingState(false)
    }
    const config = {}


    //validate form
    const result = trigger()

    result.then(function (result) {
      if (result == true) {
        setLoadingState(true)
        //submit
        securedSendRequest.execute('POST', configState.formUpdateStatusAPIUrl, submitData, onSuccess, onError, onComplete, config, null);
      }
    })

  }

  const onClear = () => {
    reset();
    setFormDetailState(false);
    setTableDetailState(false);
    setSubModuleDetailState(false);
  }

  function getUserId(action) {
    trigger();
    let username = getValues('userId');

    function onSuccess(response) {
      let data = response.data;

      if (data) {
        let error = data.errorMessages;
        if (error === null || error === undefined) {
          if (data.content && data.content.length > 0) {
            getPartyId(action, data.content[0].id);

          } else {
            showNotification('error', 'Invalid user ID.');
          }
        } else {
          showNotification('error', 'Invalid user ID.');
        }
      }
    }

    function onError(error) {
      showNotification('error', "We've encountered some issue.Please try again later.");
    }

    function onComplete() { }

    const formData = {
      baseFilterDTO: {
        filterType: 'AND',
        filterProperties: [{
          fieldName: 'loginId',
          operatorType: 'EQUAL',
          value1: username
        }],
        sortProperties: []
      }
    }

    const config = {}

    if (username) {
      securedSendRequest.execute('POST', FileForUserUrls.GET_TPR_USER, formData, onSuccess, onError, onComplete, config, null);
    }
  }

  function getPartyId(action, userProfileId) {

    function onSuccess(response) {
      let data = response.data;

      if (data) {
        let error = data.errorMessages;
        if (error === null || error === undefined) {
          if (data.content && data.content.length > 0) {
            let tprUserDetails = data.content[0].tprUserDetail;

            if (tprUserDetails != null & tprUserDetails != undefined) {
              setValue('partyId', tprUserDetails.partyId);

              if (action === SEARCH_ACTION) {
                onSearch();
              } else if (action === UPDATE_ACTION) {
                onUpdate();
              }

            }

          } else {
            showNotification('error', 'Invalid user ID.');
          }
        } else {
          showNotification('error', 'Invalid user ID.');
        }
      }
    }

    function onError(error) {
      showNotification('error', "We've encountered some issue.Please try again later.");
    }

    function onComplete() { }

    const formData = {
      baseFilterDTO: {
        filterType: 'AND',
        filterProperties: [{
          fieldName: 'userProfileId',
          operatorType: 'EQUAL',
          value1: userProfileId
        }],
        sortProperties: []
      }
    }

    const config = {}

    securedSendRequest.execute('POST', FileForUserUrls.GET_TPR_USER_PREFERENCE, formData, onSuccess, onError, onComplete, config, null);
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.dbAdminStatusConfig} />
      <CngGridItem xs={12} sm={9}>
        <CngBackdrop loading={loadingState} />
      </CngGridItem>
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.module}>
            <CngCodeMasterAutocompleteField
              name='module'
              label={configState.labelModule}
              disabled={disabled}
              codeType='DB_ADMIN_MODULE'
            />
          </CngGridItem>
          {subModuleDetalsState &&
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
              <CngCodeMasterAutocompleteField
                key={subModuleState}
                name='type'
                label={configState.labelType}
                disabled={disabled}
                codeType={configState.dropDownSubModuleType}
              />
            </CngGridItem>
          }

          {formDetailState &&
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.searchCriteria1}>
              <CngTextField
                name='searchCriteria1'
                label={configState.labelSearchCriteria1}
                disabled={disabled}
                onChange={(e) => {
                  trigger('searchCriteria1')
                }}
              />
            </CngGridItem>
          }
          {formDetailState &&
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.searchCriteria2}>
              <CngTextField
                name='searchCriteria2'
                label={configState.labelSearchCriteria2}
                disabled={disabled}
                onChange={(e) => {
                  trigger('searchCriteria2')
                }}
              />
            </CngGridItem>
          }
          {formDetailState && getValues('module') == 'ACEHW' && getValues('type') == 'ACE_INBOND' &&
            <CngGridItem xs={12} sm={6}>
              <CngCodeMasterAutocompleteField
                name='searchCriteria2'
                label={configState.labelSearchCriteria2}
                disabled={disabled}
                codeType='MESSAGE_FUNCTION_INBOND'
              />
            </CngGridItem>
          }
          {formDetailState && getValues('module') == 'ACEHW' && getValues('type') == 'INBOND_EVENT' &&
            <CngGridItem xs={12} sm={6}>
              <CngCodeMasterAutocompleteField
                name='searchCriteria2'
                label={configState.labelSearchCriteria2}
                disabled={disabled}
                codeType='ACE_HW_ABI_ACTION'
              />
            </CngGridItem>
          }
          {formDetailState &&
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.currentStatus}>
              <CngCodeMasterAutocompleteField
                key={formState}
                name='currentStatus'
                label={configState.labelCurrentStatus}
                disabled={disabled}
                codeType={configState.dropDownStatusCodeType}
                onChange={(e) => {
                  trigger('currentStatus')
                }}
              />
            </CngGridItem>
          }
          {formDetailState &&
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.newStatus}>
              <CngCodeMasterAutocompleteField
                key={formState}
                name='newStatus'
                label={configState.labelNewStatus}
                disabled={disabled}
                codeType={configState.dropDownStatusCodeType}
                onChange={(e) => {
                  trigger('newStatus')
                }}
              />
            </CngGridItem>
          }
          {formDetailState &&
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
              <CngTextField
                name='userId'
                label={configState.labelUserId}
                disabled={disabled}
                onChange={(e) => { trigger('userId') }}
              />
            </CngGridItem>
          }
        </Grid>
        <Grid>
          <StatusConfigButtonComponent
            onSearch={() => getUserId(SEARCH_ACTION)}
            onUpdate={() => getUserId(UPDATE_ACTION)}
            onClear={onClear}
            formDetailState={formDetailState}
          />
        </Grid>
        {tableDetailState &&
          <Grid>
            <Card>
              <CngSimpleCardHeader title={translatedTextsObject.result} />
              <CardContent >

                <CngCrudTable
                  key={tableState}
                  //{...props}
                  fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                  columns={configState.tableColumns}
                  fetch={{ url: configState.tableFetchAPIUrl }}
                  idAccessor='id'
                  notification={showNotification}
                  fetchFilters={configState.tableFetchFilters}
                  options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100, 500, 1000]
                  }}
                />

              </CardContent>
            </Card>
          </Grid>
        }
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
